import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DeleteIcon } from 'src/shared/assets/icons/cross.svg';
import { ReactComponent as CancelIcon } from 'src/shared/assets/icons/round-arrow-left.svg';
import { ReactComponent as DeleteImg } from 'src/shared/assets/img/delete.svg';
import { Button } from 'src/shared/components/button';
import { Modal } from 'src/shared/components/modal/modal';
import { useStore } from 'src/store';

import styles from './confirm-remove-version-modal.module.scss';

export const ConfirmRemoveVersionModal = observer(function ConfirmRemoveVersionModal() {
  const { t } = useTranslation();

  const { planVersion } = useStore();

  const isModalOpened = planVersion.isConfirmRemoveVersionModalOpened;

  return (
    <Modal
      isOpened={isModalOpened}
      className={styles.modal}
      closeOnClickOutside={false}
      loading={planVersion.isLoading}
    >
      <DeleteImg className={styles.image} />
      <h2 className={styles.title}>Удалить данные?</h2>

      {/* TODO Добавить перевод */}
      <p className={styles.descriptionParagraph}>Все данные этого черновика будут удалены, продолжить?</p>

      <div className={styles.buttonsWrapper}>
        <Button
          variant="primary"
          className={styles.button}
          iconPosition="before"
          icon={<DeleteIcon />}
          onClick={planVersion.cancelRemoveVersion}
        >
          {t('common:Buttons.cancel')}
        </Button>

        <Button
          variant="danger"
          className={styles.button}
          iconPosition="before"
          icon={<CancelIcon />}
          onClick={planVersion.confirmRemoveVersion}
        >
          {t('common:Buttons.delete')}
        </Button>
      </div>
    </Modal>
  );
});
