import { flow, makeObservable, observable } from 'mobx';

import { getIsTokenExpired } from 'src/shared/utils/auth';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { GlobalVariables } from '../global-variables/global-variables';

import { UserService } from './user-service';

export namespace Auth {
  export type UserInfo = {
    name: string;
    sub: string;
  };

  export const isUserInfo = (object: unknown): object is UserInfo =>
    typeof object === 'object' && !!object && 'name' in object;
}

export class AuthStore {
  private readonly globalVariables: GlobalVariables;
  private readonly notifications: NotificationsStore;

  @observable isAuthenticated = false;
  @observable userInfo: Auth.UserInfo | null = null;

  constructor(globalVariables: GlobalVariables, notifications: NotificationsStore) {
    this.globalVariables = globalVariables;
    this.notifications = notifications;

    makeObservable(this);
  }

  @flow.bound
  async *initUserService(): Promise<void> {
    if (getIsTokenExpired()) {
      const isAuthenticated = await UserService.initUserServiceWithCheck();
      yield;

      this.isAuthenticated = isAuthenticated || false;
    } else {
      await UserService.initUserService();
      yield;

      this.isAuthenticated = true;
    }
  }

  @flow.bound
  async *loadUserData() {
    try {
      const userData = await UserService.getUserData();
      yield;

      if (userData) {
        const userInfo = userData;

        if (Auth.isUserInfo(userInfo)) {
          this.userInfo = userInfo;

          this.globalVariables.addVariable('ownerUserId', userInfo.sub);
        }
      }
    } catch (e) {
      yield;

      console.error(e);
      this.notifications.showErrorMessageT('errors:failedToLoadUserData');
    }
  }

  @flow.bound
  async *logout() {
    try {
      await UserService.logout();
      yield;

      this.userInfo = null;
      this.isAuthenticated = false;
    } catch (e) {
      yield;

      console.error(e);
      this.notifications.showErrorMessageT('errors:failedToSignOut');
    }
  }
}
