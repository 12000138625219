import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import {
  WellInfoSidebar as WellInfoSidebarTypes,
  WellInfoSidebarStore,
} from 'src/features/well-info-sidebar/well-info-sidebar.store';
import { ReactComponent as EditIcon } from 'src/shared/assets/icons/pencil.svg';
import { Button } from 'src/shared/components/button';
import { Loader } from 'src/shared/components/loader';
import { Sidebar } from 'src/shared/components/sidebar/sidebar';
import { SwitchButtonGroup } from 'src/shared/components/switch-button-group';
import { useStore } from 'src/store';

import { InfoSidebarTabContent } from '../graph-info-sidebar/info-sidebar-tab-component/info-sidebar-tab-component';

import styles from './well-info-sidebar.module.scss';

export const DEFAULT_WELL_SIDEBAR_TAB = WellInfoSidebarTypes.Tabs.mainInfo;

interface Props {
  isOpen: boolean;
  wellId: number | string;
  onClose: VoidFunction;
  isEditButtonDisabled?: boolean;
  disableEditing?: boolean;
  onEdit?(wellId: number | string): void;
  initialActiveTab?: WellInfoSidebarTypes.Tabs;
}

export const WellInfoSidebar = observer(function WellInfoSidebar({
  isOpen,
  wellId,
  initialActiveTab = DEFAULT_WELL_SIDEBAR_TAB,
  isEditButtonDisabled,
  onEdit,
  onClose,
  disableEditing,
}: Props) {
  const rootStore = useStore();
  const [store] = useState(() => new WellInfoSidebarStore(rootStore));
  const [activeTabName, setActiveTabName] = useState<string>(initialActiveTab);

  useEffect(() => {
    if (!store.viewProvider) {
      store.init();
    }
  }, [store, store.init]);

  useEffect(() => {
    if (store.viewProvider) {
      store.loadWell(wellId);
    }
  }, [wellId, store.viewProvider, store]);

  useEffect(() => {
    setActiveTabName(initialActiveTab);
  }, [initialActiveTab]);

  const handleEdit = () => {
    onClose();
    onEdit?.(wellId);
  };

  const getTitle = (): string | undefined => {
    if (store.wellStore.isLoading) {
      return;
    }

    if (store.wellStore.selectedWell && store.viewProvider) {
      return store.getSidebarTitle(store.wellStore.selectedWell) || '';
    }
  };

  const tabs = store.viewProvider?.tabsSwitchButtonsGroup || [];
  const activeTab = store.viewProvider?.tabs.find((tab) => tab.fieldId === activeTabName);

  return (
    <Sidebar isOpened={isOpen} onClose={onClose} title={getTitle()} size="l" key={wellId}>
      <div className={styles.header}>
        <SwitchButtonGroup
          items={tabs}
          defaultOptionKey={activeTabName}
          className={styles.tabs}
          disabled={store.wellStore.isLoading}
          onChange={setActiveTabName}
        />

        {!disableEditing && rootStore.editing.isCanEditing && (
          <Button
            icon={<EditIcon className={styles.editIcon} />}
            className={styles.editButton}
            disabled={isEditButtonDisabled}
            onClick={handleEdit}
          />
        )}
      </div>

      <div className={styles.tabContent}>
        {store.wellStore.isLoading || store.isLoading ? (
          <Loader className={styles.loader} />
        ) : (
          <div className={styles.tabContentInner}>
            <InfoSidebarTabContent activeTab={activeTab} />
          </div>
        )}
      </div>
    </Sidebar>
  );
});
