import { Progress } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowRightIcon } from 'src/shared/assets/icons/arrow-right-long.svg';
import { ReactComponent as CrossIcon } from 'src/shared/assets/icons/cross.svg';
import { ReactComponent as ExclamationMarkIcon } from 'src/shared/assets/icons/exclamation-mark.svg';
import { Button } from 'src/shared/components/button';
import { Loader } from 'src/shared/components/loader';
import { useStore } from 'src/store';
import { PlanVersionType } from 'src/store/comparison/types';
import { ImportJob } from 'src/store/jobs/import-job';

import styles from './import-progress.module.scss';

interface Props {
  job: ImportJob;
}

export const ImportProgress = observer<Props>(function ImportProgress({ job }) {
  const { router, drafts, planVersion, comparison, notifications, jobsStore } = useStore();
  const { t } = useTranslation();

  const processedRows = job.result?.processedRows;
  const totalRows = job.result?.totalRows;

  const hasResult = job.result?.processedRows !== undefined && job.result.totalRows !== undefined;
  const isInProgress = job.status === 'IN_PROGRESS' && hasResult;
  const isInLoading = job.status === 'NEW' || (job.status === 'IN_PROGRESS' && !hasResult);
  const isCompletedSuccessfully = job.status === 'COMPLETED' && !job.errorsCount;
  const isCompletedWithError = job.status === 'COMPLETED' && !!job.errorsCount;

  const handleGoToImportButtonClick = async () => {
    const importVersionId = job.result?.planVersionId;

    if (importVersionId === undefined) {
      notifications.showErrorMessageT('errors:failedToCompleteImport');
      return;
    }

    if (isCompletedWithError) {
      router.push(`/carpet/conflicts?planVersionId=${importVersionId}`);
    }

    if (isCompletedSuccessfully) {
      await drafts.fetchDraftsList();

      const importPlanVersion = drafts.draftsList.find((version) => version.id === importVersionId);
      const currentVersion = planVersion.actualVersions.find(
        (version) => version.data.versionType === PlanVersionType.current
      );

      if (!planVersion.version || !importPlanVersion || !currentVersion) {
        notifications.showErrorMessageT('errors:failedToCompleteImport');
        return;
      }

      comparison.submitComparingWithImport(currentVersion, importPlanVersion);
    }
  };

  const handleCancelImport = async (): Promise<void> => {
    await jobsStore.cancelJob(job.id);
  };

  if (!isInLoading && !isInProgress && !isCompletedSuccessfully && !isCompletedWithError) {
    return null;
  }

  return (
    <div
      className={clsx(
        styles.wrapper,
        isInLoading && styles.wrapper__inLoading,
        isInProgress && styles.wrapper__inProgress,
        isCompletedWithError && styles.wrapper__completedWithError,
        isCompletedSuccessfully && styles.wrapper__completed
      )}
    >
      {isInLoading && (
        <>
          <Loader size="small" />

          <p className={styles.importInfo}>Инициализация импорта</p>

          <Button
            icon={<CrossIcon />}
            iconPosition="before"
            className={styles.cancelImportButton}
            onClick={handleCancelImport}
          >
            {t('common:Buttons.cancel')}
          </Button>
        </>
      )}

      {isInProgress && (
        <>
          <div>
            <div className={styles.importInfo}>
              Импортировано скважин: {processedRows} из {totalRows}
            </div>

            {processedRows !== undefined && totalRows !== undefined && (
              <Progress
                className={styles.progressBar}
                percent={(processedRows / totalRows) * 100}
                strokeWidth={2}
                showInfo={false}
                strokeColor="#7471FF"
                style={{ color: '#7471FF' }}
              />
            )}
          </div>

          <Button
            icon={<CrossIcon />}
            iconPosition="before"
            className={styles.cancelImportButton}
            onClick={handleCancelImport}
          >
            {t('common:Buttons.cancel')}
          </Button>
        </>
      )}

      {isCompletedWithError && (
        <>
          <ExclamationMarkIcon className={styles.exclamationMarkIcon} />

          <div className={styles.importInfo}>
            Скважин в конфликте {job.errorsCount} из {totalRows}
          </div>

          <Button
            variant="link"
            className={styles.goToImportButton}
            icon={<ArrowRightIcon />}
            onClick={handleGoToImportButtonClick}
          >
            Перейти
          </Button>
        </>
      )}

      {isCompletedSuccessfully && (
        <>
          <div className={styles.importInfo}>
            Импортировано скважин: {processedRows} из {totalRows}
          </div>

          <Button
            variant="link"
            className={styles.goToImportButton}
            icon={<ArrowRightIcon />}
            onClick={handleGoToImportButtonClick}
          >
            Перейти
          </Button>
        </>
      )}
    </div>
  );
});
