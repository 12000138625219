import { action, computed, flow, makeObservable, observable } from 'mobx';

import { PlanVersionsApi } from 'src/api/plan-versions';
import { createPrivateDraft, deleteCarpetVersion, toggleVersionForAnalyticsRequest } from 'src/api/version';
import { BaseApiError } from 'src/errors';
import { hasValue } from 'src/shared/utils/common';
import { TPromiseController, createPromiseController } from 'src/shared/utils/promise-controller';
import {
  PlanVersion,
  PlanVersionType,
  TActualPlansGroups,
  TAnalyticsPlansGroup,
  TArchivedPlansGroup,
} from 'src/store/comparison/types';
import { processActualData, getVersionsYearGroupsWithMonths } from 'src/store/comparison/utils';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

export class PlanVersionStore {
  private readonly api = new PlanVersionsApi();
  private readonly notifications: NotificationsStore;
  private removeVersionController: TPromiseController<boolean> | null = null;

  @observable isConfirmRemoveVersionModalOpened: boolean = false;
  @observable actualVersions: PlanVersion[] = [];
  @observable archivedVersions: PlanVersion[] = [];
  @observable analyticsVersions: PlanVersion[] = [];
  @observable isLoading = false;
  @observable version?: PlanVersion;
  chosenAnalyticsVersions = observable.map<number, PlanVersion>();

  constructor(notifications: NotificationsStore) {
    this.notifications = notifications;

    makeObservable(this);
  }

  @action.bound
  private initChosenAnalyticsVersions(): void {
    this.analyticsVersions.forEach((plan) => {
      if (plan.data.availableForDashboardLite) {
        this.chosenAnalyticsVersions.set(plan.id, plan);
      }
    });
  }

  @action.bound
  private openConfirmRemoveVersionModalOpened(): void {
    this.isConfirmRemoveVersionModalOpened = true;
  }
  @action.bound
  private closeConfirmRemoveVersionModalOpened(): void {
    this.isConfirmRemoveVersionModalOpened = false;
  }

  @action.bound
  confirmRemoveVersion(): void {
    this.removeVersionController?.resolve(true);
  }

  @action.bound
  cancelRemoveVersion(): void {
    this.removeVersionController?.resolve(false);
  }

  @flow.bound
  private async *loadVersions() {
    try {
      this.isLoading = true;

      const data: PlanVersion[] = await this.api.getVersions();
      yield;

      this.actualVersions = data
        .filter((plan) => !plan.data.isArchive)
        .sort(({ createdAt: firstCreatedAt }, { createdAt: secondCreatedAt }) => secondCreatedAt - firstCreatedAt);

      this.archivedVersions = data
        .filter((plan) => plan.data.isArchive)
        .sort(({ createdAt: firstCreatedAt }, { createdAt: secondCreatedAt }) => secondCreatedAt - firstCreatedAt);

      this.analyticsVersions = this.archivedVersions.filter(
        (plan) => plan.data.versionType === PlanVersionType.current
      );

      this.initChosenAnalyticsVersions();

      // Set last actual public version by default.
      const defaultPlanVersion = this.actualVersions.find(
        ({ data: { versionType } }) => versionType === PlanVersionType.public
      );

      if (defaultPlanVersion !== undefined) {
        this.version = defaultPlanVersion;
      } else {
        this.notifications.showErrorMessageT('errors:actualVersionNotFound');
      }
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadVersions');
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  async *toggleVersionForAnalytics(version: PlanVersion) {
    if (this.chosenAnalyticsVersions.has(version.id)) {
      try {
        await toggleVersionForAnalyticsRequest(version.id, false);
        yield;

        this.chosenAnalyticsVersions.delete(version.id);
      } catch (e) {
        yield;
        if (e instanceof BaseApiError) {
          const message = e.message || e.responseMessage;

          if (message) {
            this.notifications.showErrorMessage(message);
          }
        }
      }
      return;
    }

    try {
      await toggleVersionForAnalyticsRequest(version.id, true);
      yield;

      this.chosenAnalyticsVersions.set(version.id, version);
    } catch (e) {
      yield;
      if (e instanceof BaseApiError) {
        const message = e.message || e.responseMessage;

        if (message) {
          this.notifications.showErrorMessage(message);
        }
      }
    }
  }

  @flow.bound
  async *reloadVersions(isSwitchDefaultVersion: boolean = true) {
    try {
      const data: PlanVersion[] = await this.api.getVersions();
      yield;

      this.actualVersions = data
        .filter((plan) => !plan.data.isArchive)
        .sort(({ createdAt: firstCreatedAt }, { createdAt: secondCreatedAt }) => secondCreatedAt - firstCreatedAt);

      this.archivedVersions = data
        .filter((plan) => plan.data.isArchive)
        .sort(({ createdAt: firstCreatedAt }, { createdAt: secondCreatedAt }) => secondCreatedAt - firstCreatedAt);

      this.analyticsVersions = this.archivedVersions.filter(
        (plan) => plan.data.versionType === PlanVersionType.current
      );

      this.initChosenAnalyticsVersions();

      // Set last actual public version by default.
      const defaultPlanVersion = this.actualVersions.find(
        ({ data: { versionType } }) => versionType === PlanVersionType.public
      );

      if (isSwitchDefaultVersion && defaultPlanVersion !== undefined) {
        this.version = defaultPlanVersion;
      } else if (defaultPlanVersion === undefined) {
        this.notifications.showErrorMessageT('errors:actualVersionNotFound');
      }
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadVersions');
    }
  }

  @flow.bound
  async *deleteVersionById(versionId: number) {
    try {
      this.removeVersionController = createPromiseController<boolean>();

      this.openConfirmRemoveVersionModalOpened();

      const isDeleteVersion = await this.removeVersionController;

      if (!isDeleteVersion) return;

      await deleteCarpetVersion(versionId);
      await this.reloadVersions(false);
      yield;
    } catch (e) {
      yield;
      console.error(e);
      throw e;
    } finally {
      this.closeConfirmRemoveVersionModalOpened();
    }
  }

  @flow.bound
  async *createPrivateDraft() {
    if (!hasValue(this.version)) {
      return;
    }

    try {
      const privateVersionId = await createPrivateDraft(this.version.id);

      await this.reloadVersions();
      yield;
      this.onVersionChange(privateVersionId);
    } catch (e) {
      yield;
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('errors:failedToCreatePrivateDraft');
      console.error(e);
    }
  }

  init = (): void => {
    this.loadVersions();
  };

  @action.bound
  onVersionChange(versionId: number): void {
    for (const group of this.actualData) {
      for (const plan of group.plans) {
        if (plan.id === versionId) {
          this.version = plan;
          return;
        }
      }
    }
    for (const group of this.archivedData) {
      for (const monthGroup of group.months) {
        for (const plan of monthGroup.plans) {
          if (plan.id === versionId) {
            this.version = plan;
            return;
          }
        }
      }
    }
  }

  @computed
  get actualData(): [TActualPlansGroups, TActualPlansGroups] {
    return processActualData(this.actualVersions);
  }

  @computed
  get archivedData(): TArchivedPlansGroup[] {
    return getVersionsYearGroupsWithMonths('archived', this.archivedVersions);
  }

  @computed
  get analyticsData(): TAnalyticsPlansGroup[] {
    return getVersionsYearGroupsWithMonths('analytics', this.analyticsVersions);
  }

  @computed
  get publicDraft(): PlanVersion | null {
    return this.actualVersions.find((plan) => plan.data.versionType === PlanVersionType.public) || null;
  }

  @computed
  get lastImportPlanVersionId(): number | undefined {
    const imports = this.actualVersions.filter(
      (item) => item.data.versionType === 'TEMP_DRAFT' && item.data.name.startsWith('import')
    );

    return imports.at(-1)?.id;
  }
}
