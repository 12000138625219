import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { WellForm } from 'src/features/well-form';
import { useStore } from 'src/store';

import { WellImportSubheaderModule } from '../import-matching-page/well-import-subheader-module';

import { ImportConflictsStore } from './import-conflicts-page.store';

import styles from './import-conflicts-page.module.scss';

export const ImportConflictsPage = observer(function ImportMatchingPage() {
  const store = useStore();

  const [params] = useSearchParams();
  const planVersionId = params.get('planVersionId');

  const [importConflictsStore] = useState(() => {
    if (!planVersionId) {
      return null;
    }

    return new ImportConflictsStore(store, Number(planVersionId));
  });

  useEffect(() => {
    if (!importConflictsStore) return;

    importConflictsStore.effect();
  }, [importConflictsStore]);

  if (!importConflictsStore) return null;

  if (importConflictsStore.isLoading) {
    return <Spin size="large" />;
  }

  return (
    <div className={styles.pageWrapper}>
      <WellForm
        wellFormManager={importConflictsStore.formManager}
        isFormFetching={importConflictsStore.formManager.isSaveUpdateLoading}
        isFormLoading={importConflictsStore.formManager.isFormLoading}
        onSplitApproach={importConflictsStore.formManager.splitApproach}
        onAddNewApproach={importConflictsStore.formManager.addNewApproach}
        onApproachBlur={importConflictsStore.formManager.createOrUpdateWell}
        subheader={<WellImportSubheaderModule importConflicts={importConflictsStore} />}
      />
    </div>
  );
});
