import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ImportConflictsStore } from 'src/pages/import-conflicts-page/import-conflicts-page.store';
import { ReactComponent as CheckmarkIcon } from 'src/shared/assets/icons/checkmark.svg';
import { ReactComponent as CrossIcon } from 'src/shared/assets/icons/cross.svg';
import { ReactComponent as AttentionIcon } from 'src/shared/assets/icons/exclamation-mark.svg';
import { ReactComponent as ClockIcon } from 'src/shared/assets/icons/simple-clock.svg';
import { Button } from 'src/shared/components/button';
import { InfoButton } from 'src/shared/components/info-button';
import { MenuSubheaderLayout } from 'src/shared/layouts/menu-subheader-layout/menu-subheader-layout';

import styles from './well-import-subheader-module.module.scss';

type Props = {
  importConflicts: ImportConflictsStore;
};

export const WellImportSubheaderModule = observer(function WellImportSubheaderModule({ importConflicts }: Props) {
  const { t } = useTranslation();

  const onImportCancel = () => {
    importConflicts.formManager.cancelForm();
  };

  const onExcludeTriple = () => {
    importConflicts.excludeTriple();
  };

  const onSuspendResolveConflicts = (): void => {
    importConflicts.onSuspendResolveConflicts();
  };

  const errorCount = importConflicts.triple?.$validation?.length ?? 0;

  return (
    <MenuSubheaderLayout classname={styles.wrapper}>
      <p className={styles.wellName}>{t('matching:SubheaderFormModule.title')}</p>
      <div
        className={styles.conflictsCounter}
        title={t('matching:SubheaderFormModule.conflicts', { count: errorCount })}
      >
        <AttentionIcon className={styles.atentionIcon} />
        <p className={styles.conflictsCounterText}>
          {t('matching:SubheaderFormModule.conflicts', { count: errorCount })}
        </p>
      </div>

      <div className={styles.buttonsWrapper}>
        <div className={styles.buttonsGroup}>
          <Button className={styles.cancelButton} variant="danger" prefixIcon={<CrossIcon />} onClick={onImportCancel}>
            <p className={styles.buttonText}>{t('matching:SubheaderFormModule.cancelImport')}</p>
          </Button>
          <InfoButton text={t('matching:SubheaderFormModule.cancelImportTooltip')} />
        </div>
        <div className={styles.buttonsGroup}>
          <Button className={styles.cancelButton} variant="danger" prefixIcon={<CrossIcon />} onClick={onExcludeTriple}>
            <p className={styles.buttonText}>{t('matching:SubheaderFormModule.excludeWell')}</p>
          </Button>
          <InfoButton text={t('matching:SubheaderFormModule.excludeWellTooltip')} />
        </div>

        <div className={styles.controlButtonsGroup}>
          <Button
            variant="success"
            prefixIcon={<CheckmarkIcon />}
            onClick={importConflicts.formManager.saveForm}
            disabled={!importConflicts.formManager.currentFormStore?.validationManager.isAllNecessaryControlsAreFilled}
          >
            {t('common:Buttons.save')}
          </Button>

          <Button variant="primary" prefixIcon={<ClockIcon />} onClick={onSuspendResolveConflicts}>
            {/* TODO Написать перевод */}
            Решить конфликты позже
          </Button>
        </div>
      </div>
    </MenuSubheaderLayout>
  );
});
