import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { exportPlanVersion } from 'src/api/export/export';
import { BaseApiError, OperationAbortedError } from 'src/errors';
import { ReactComponent as Cross } from 'src/shared/assets/icons/cross.svg';
import { ReactComponent as UploadIcon } from 'src/shared/assets/icons/upload-arrow.svg';
import { ButtonWithTooltip } from 'src/shared/components/button-with-tooltip';
import { IconButton } from 'src/shared/components/icon-button';
import { Loader } from 'src/shared/components/loader';
import { downloadFile } from 'src/shared/utils/download-file';
import { useStore } from 'src/store';
import { addCreationDateToPlanName } from 'src/store/comparison/utils';

import styles from './compare-and-import-export.module.scss';

export const ExportButton = observer(function ExportButton() {
  const { t } = useTranslation('drillingCarpet');
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { comparison, planVersion, editing, notifications } = useStore();
  const currentPlanVersion = planVersion.version;

  async function exportVersion(): Promise<void> {
    const localAbortController = new AbortController();
    setAbortController(localAbortController);

    if (currentPlanVersion) {
      const planVersionId = currentPlanVersion.id;
      const planName = addCreationDateToPlanName(currentPlanVersion).data.name;

      setIsLoading(true);
      try {
        const file = await exportPlanVersion('xlsx', planVersionId, planName, localAbortController.signal);
        downloadFile(file);
      } catch (e) {
        if (e instanceof OperationAbortedError) {
          return;
        }
        if (e instanceof BaseApiError && (e.responseMessage || e.message)) {
          notifications.showErrorMessage(e.responseMessage || e.message);
          return;
        }
        notifications.showErrorMessageT('errors:failedToExportData');
      } finally {
        setAbortController(null);
        setIsLoading(false);
      }
    }
  }

  if (isLoading) {
    return (
      <div className={clsx(styles.loaderAndAbortButtonWrapper)}>
        <Loader size="small" className={styles.exportLoader} />
        <IconButton icon={<Cross />} onClick={() => abortController?.abort()} className={styles.abortButton} />
      </div>
    );
  }

  return (
    <ButtonWithTooltip
      onClick={exportVersion}
      icon={<UploadIcon className={styles.icon} />}
      isDisabled={comparison.isComparing || editing.isEditing}
      className={clsx(styles.iconButton, comparison.isComparing && styles.hidden)}
      tooltipText={t('common:export')}
    />
  );
});
