import { makeObservable, observable, flow, action, computed } from 'mobx';

import { DraftApi } from 'src/api/draft';
import { createDraft, deleteCarpetVersion, publishVersion, saveToPublicDraft, saveVersion } from 'src/api/version';
import { hasValue } from 'src/shared/utils/common';
import { ConflictResolver } from 'src/shared/utils/conflict-resolver';
import { PlanVersion } from 'src/store/comparison/types';
import { DRAFT_ASSIGNMENT } from 'src/store/drafts/types';
import { RouterStore } from 'src/store/router/router-store';

import { JobsStore } from '../jobs/jobs-store';
import { RootStore } from '../root-store';

export class DraftsStore {
  private readonly api = new DraftApi();
  private readonly jobsStore: JobsStore;

  readonly conflictResolver: ConflictResolver;
  readonly router: RouterStore;

  @observable draftVersionId: number | null = null;
  @observable parentVersionId: number | null = null;
  @observable draftsList: PlanVersion[] = [];

  constructor(rootStore: RootStore) {
    this.jobsStore = rootStore.jobsStore;
    this.router = rootStore.router;
    this.conflictResolver = new ConflictResolver(rootStore);

    makeObservable(this);
  }

  init(): void {
    this.fetchDraftsList();
  }

  private generateDraftName(draftAssignment: DRAFT_ASSIGNMENT): string {
    return `${draftAssignment}_${(+new Date()).toString(36)}`;
  }

  @computed
  get draftName(): string | null {
    return this.draftsList.find((draft) => draft.id === this.draftVersionId)?.data.name || null;
  }

  @computed
  get hasDraft(): boolean {
    return hasValue(this.draftVersionId);
  }

  @action.bound
  setDraft(draftVersionId: number, parentVersionId: number | null) {
    this.draftVersionId = draftVersionId;
    this.parentVersionId = parentVersionId;
  }

  @flow.bound
  async *fetchDraftsList() {
    try {
      const planVersions: PlanVersion[] = await this.api.getVersions();
      yield;

      this.draftsList = planVersions.sort((firstDraft, secondDraft) => secondDraft.createdAt - firstDraft.createdAt);
    } catch (e) {
      yield;
      console.error(e);
      throw e;
    }
  }

  @flow.bound
  async *createDraft(planVersionId: number, draftAssignment: DRAFT_ASSIGNMENT) {
    const draftName = this.generateDraftName(draftAssignment);

    const draftVersionId = await createDraft(planVersionId, draftName);
    yield;
    this.draftVersionId = draftVersionId;
    this.parentVersionId = planVersionId;
  }

  @flow.bound
  async *deleteCurrentDraft() {
    if (hasValue(this.draftVersionId)) {
      await deleteCarpetVersion(this.draftVersionId);
      await this.fetchDraftsList();
      yield;
      this.draftVersionId = null;
      this.parentVersionId = null;
    }
  }

  @flow.bound
  async *deleteDraftById(draftVersionId: number) {
    try {
      if (this.jobsStore.activeImportJob?.result?.planVersionId === draftVersionId) {
        this.jobsStore.cancelJob(this.jobsStore.activeImportJob.id);
      }

      await deleteCarpetVersion(draftVersionId);
      await this.fetchDraftsList();
    } catch (e) {
      yield;
      console.error(e);
      throw e;
    }
  }

  @flow.bound
  async *publishDraft() {
    if (hasValue(this.draftVersionId)) {
      await publishVersion(this.draftVersionId);
      yield;
      this.draftVersionId = null;
      this.parentVersionId = null;
    }
  }

  @flow.bound
  async *saveToPublicDraft() {
    if (hasValue(this.draftVersionId)) {
      await saveToPublicDraft(this.draftVersionId);
      await this.deleteCurrentDraft();
      yield;
      this.draftVersionId = null;
      this.parentVersionId = null;
    }
  }

  @flow.bound
  async *saveDraft() {
    if (hasValue(this.draftVersionId) && hasValue(this.parentVersionId)) {
      await saveVersion(this.draftVersionId, this.parentVersionId);
      await this.deleteCurrentDraft();
      yield;

      this.draftVersionId = null;
      this.parentVersionId = null;
    }
  }
}
