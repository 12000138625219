import { observable, makeObservable, flow, action, when, computed } from 'mobx';

import { getWellFormViewAndItsDirectories, updateWell, createWell } from 'src/api/new-well/requests';
import { mapApproach } from 'src/api/new-well/serializers/approaches-serializers';
import { mapData } from 'src/api/new-well/serializers/common-serializers';
import { serializeApproach } from 'src/api/new-well/serializers/serialize-form-for-request';
import { TIdTriple, TFormRaw, TCreateUpdateWellReturnType } from 'src/api/new-well/types';
import { BaseApiError, Validation400ApiError, ValidationError } from 'src/errors';
import { ConflictResolvingError } from 'src/errors/conflicts';
import { AddWellToChartSidebarStore } from 'src/features/add-well-to-chart-sidebar/add-well-to-chart-sidebar.store';
import { ApproachesTab } from 'src/features/well-form/entities/approach-entities';
import { FormStore } from 'src/features/well-form/entities/form.entity';
import { FormPlugin } from 'src/features/well-form/plugins';
import { TSplitApproachOptions } from 'src/features/well-form/types';
import { RegularComboBox } from 'src/shared/entities/control-entities';
import { assert } from 'src/shared/utils/assert';
import { hasValue } from 'src/shared/utils/common';
import { getInitialTripleIdsByWell } from 'src/shared/utils/get-triple-ids';
import { ENABLE_EDITING_VARIANTS } from 'src/store/editing/types';

import { Directories } from '../directories/directories.store';
import { DraftsStore } from '../drafts/drafts-store';
import { EditingStore } from '../editing/editing-store';
import { I18NextStore } from '../i18next/i18next-store';
import { NotificationsStore } from '../notifications-store/notifications-store';
import { PlanVersionStore } from '../plan-version';
import { RootStore } from '../root-store';

/** This class uses for form managment on Wells, Planning and Graph pages */
export class WellFormManager {
  readonly directories: Directories;
  readonly notifications: NotificationsStore;
  readonly i18: I18NextStore;
  readonly planVersion: PlanVersionStore;
  readonly editing: EditingStore;
  readonly drafts: DraftsStore;
  readonly formPlugins: FormPlugin[];
  readonly addWellToChartStore: AddWellToChartSidebarStore;
  protected readonly rootStore: RootStore;

  @observable tripleIds: TIdTriple[] | null = null;
  @observable isFormLoading = false;
  @observable isFormOpen = false;
  @observable isAddWellToChartSidebarOpened = false;
  @observable currentFormStore: FormStore | null = null;
  @observable formList: FormStore[] = [];
  @observable tripleIdsList: Record<number, TIdTriple[]> = {};
  @observable formView?: TFormRaw;
  @observable isSaveUpdateLoading = false;
  @observable isFormRequestError = false;
  @observable currentSaveUpdateRequest: Promise<TCreateUpdateWellReturnType> | null = null;

  onCloseForm?(): void | Promise<void>;
  onFormSaveSuccess?(): void | Promise<void>;
  onFormCancel?(): void | Promise<void>;
  onFormInit?(): void | Promise<void>;
  onCreateWell?(well: TCreateUpdateWellReturnType): void | Promise<void>;
  onUpdateWell?(well: TCreateUpdateWellReturnType): void | Promise<void>;

  constructor(rootStore: RootStore, formPlugins: FormPlugin[] = []) {
    this.directories = rootStore.directories;
    this.notifications = rootStore.notifications;
    this.i18 = rootStore.i18;
    this.planVersion = rootStore.planVersion;
    this.editing = rootStore.editing;
    this.drafts = rootStore.drafts;
    this.formPlugins = formPlugins;
    this.rootStore = rootStore;
    this.addWellToChartStore = new AddWellToChartSidebarStore({
      draftStore: rootStore.drafts,
      directories: rootStore.directories,
      appSettings: rootStore.appSettings,
      notifications: rootStore.notifications,
      wellFormManager: this,
      view: rootStore.views.addWellToChart,
    });

    makeObservable(this);
  }

  @action.bound
  private checkIsWellPlacementIdUpdated(oldTripleIds: TIdTriple[], newTripleIds: TIdTriple[]) {
    const oldWellPlacementId = oldTripleIds.find((triples) => triples.objectType === 'GOplan_WellPlacement')?.id;
    const newWellPlacementId = newTripleIds.find((triples) => triples.objectType === 'GOplan_WellPlacement')?.id;
    if (oldWellPlacementId !== newWellPlacementId) {
      for (const trieplIds of Object.values(this.tripleIdsList)) {
        const triple = trieplIds.find((triple) => triple.objectType === 'GOplan_WellPlacement');
        if (triple && newWellPlacementId) {
          triple.id = newWellPlacementId;
        }
      }
    }
  }

  @flow.bound
  private async *loadFormView() {
    try {
      const formView = await getWellFormViewAndItsDirectories(this.directories);
      yield;

      this.formView = formView;
    } catch (e) {
      yield;

      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('newWellForm:Errors.failedLoadForm');
    }
  }

  async init(): Promise<void> {
    await this.loadFormView();
    await this.onFormInit?.();
  }

  @computed
  get currentWellName(): string {
    if (!this.currentFormStore?.isEditingMode) {
      return this.i18.t('newWellForm:Subheader.title');
    }

    const wellControl = this.currentFormStore?.fields['wellName'];
    if (wellControl instanceof RegularComboBox) {
      const wellDirValue = this.directories
        .getObject(wellControl.refObjectType)
        ?.find((dirValue) => dirValue.id === wellControl.value);

      if (wellControl.refObjectAttr) {
        const wellName = wellDirValue?.data[wellControl.refObjectAttr];
        return wellName?.toString() || '';
      }
    }

    return '';
  }

  @flow.bound
  async *changeForm(formId: number) {
    if (!this.currentFormStore) {
      return;
    }

    if (this.currentFormStore.validationManager.validationRequest) {
      const isValidationPassed = await this.currentFormStore.validationManager.validationRequest;
      yield;

      this.currentFormStore.validationManager.validationRequest = null;

      if (!isValidationPassed) {
        return;
      }
    }

    if (this.isSaveUpdateLoading) {
      await when(() => !this.isSaveUpdateLoading);
      yield;
    } else {
      await this.createOrUpdateWell();
      yield;
    }

    if (!this.isFormRequestError && this.currentFormStore?.validationManager.isAllNecessaryControlsAreFilled) {
      const newCurrentForm = this.formList.find((form) => form.id === formId);
      const newTripleIds = this.tripleIdsList[formId];

      if (newCurrentForm && newTripleIds) {
        this.currentFormStore = newCurrentForm;
        this.tripleIds = newTripleIds;
      }
    }
  }

  @action.bound
  setIsFormOpen(is: boolean) {
    this.isFormOpen = is;
  }

  @action.bound
  createForm(well?: { well: Record<string, unknown>; setBy: 'attrName' | 'fieldId' }) {
    if (!this.formView) {
      return;
    }
    const newForm = mapData(this.formView, null, this.rootStore, this.formPlugins);
    this.currentFormStore = newForm;
    this.formList.push(newForm);

    if (well) {
      newForm.setFormValues(well.well);
      const newTripleIds = getInitialTripleIdsByWell(well.well);
      this.tripleIdsList[newForm.id] = newTripleIds;
      this.tripleIds = newTripleIds;
    }
  }

  @flow.bound
  async *onWellAdd() {
    if (!this.formView) {
      this.notifications.showErrorMessageT('newWellForm:Errors.failedOpenEditForm');
      return;
    }

    this.isFormLoading = true;
    this.isFormOpen = true;
    try {
      if (!this.editing.isEditing) {
        const enableEditingVariant = await this.editing.enableEditing();
        yield;

        if (enableEditingVariant === ENABLE_EDITING_VARIANTS.cancel) {
          return;
        }
      }
      this.currentFormStore = mapData(this.formView, null, this.rootStore, this.formPlugins);
    } catch (e) {
      yield;
      console.error(e);
      this.isFormOpen = false;
    } finally {
      this.isFormLoading = false;
    }
  }

  @flow.bound
  async *addNewApproach() {
    const isSectionDetailingEnabled = !!this.currentFormStore?.fields['sectionsDetailing'].value;

    if (!isSectionDetailingEnabled && this.currentFormStore?.approaches.length) {
      this.notifications.showErrorMessageT('newWellForm:Errors.sectionDetailingIsDisabledApproach');
      return;
    }

    if (this.currentFormStore?.approaches.length) {
      await this.createOrUpdateWell();
      yield;
    }

    if (
      (!this.isFormRequestError &&
        this.currentFormStore?.validationManager.isAllNecessaryControlsAreFilled &&
        isSectionDetailingEnabled) ||
      !this.currentFormStore?.approaches.length
    ) {
      this.currentFormStore?.addNewApproach();
    }
  }

  @flow.bound
  async *splitApproach({ sectionId, stage, approach }: TSplitApproachOptions) {
    const isSectionDetailingEnabled = !!this.currentFormStore?.fields['sectionsDetailing'].value;

    if (!isSectionDetailingEnabled) {
      this.notifications.showErrorMessageT('newWellForm:Errors.sectionDetailingIsDisabledApproach');
      return;
    }

    await this.createOrUpdateWell();
    yield;

    if (this.isFormRequestError) {
      return;
    }

    const approachesTab = this.currentFormStore?.tabs.find((tab): tab is ApproachesTab => tab instanceof ApproachesTab);
    if (!approachesTab) {
      return;
    }
    if (!approachesTab.approachesList.approachReference) return;
    const splitSectionIndex = stage.sectionsList.sections.findIndex((section) => section.id === sectionId);
    const clonedApproach = approach.clone();
    if (!clonedApproach) return;
    const clonedStage = stage.clone();
    const splicedSections = stage.sectionsList.sections.splice(splitSectionIndex + 1);
    clonedStage.sectionsList.setSections(splicedSections);

    clonedApproach.stagesList.setStages([clonedStage]);
    const serializedApproach = serializeApproach(clonedApproach);

    const approachReference = approachesTab.approachesList.approachReference;
    const approachControl = mapApproach({ approachReference, directories: this.directories }, serializedApproach);
    if (approachControl) {
      approachesTab.approachesList.approaches.push(approachControl);
    }
  }

  @flow.bound
  private async *updateWell(): Promise<TCreateUpdateWellReturnType> {
    if (!this.currentFormStore) {
      return;
    }

    assert(this.tripleIds, 'tripleIds is invalid');
    assert(this.drafts.draftVersionId, 'draftVersionId is invalid');
    const well = await updateWell(this.currentFormStore.tabs, this.drafts.draftVersionId, this.tripleIds);
    yield;

    this.tripleIds = well.idList;
    const currentWellTripleIds = this.tripleIdsList[this.currentFormStore.id];
    this.checkIsWellPlacementIdUpdated(currentWellTripleIds, well.idList);
    this.tripleIdsList[this.currentFormStore.id] = well.idList;
    this.currentFormStore.setRigOperationsIds(well.idList);
    await this.onUpdateWell?.(well);

    const withoutPreloader = true;
    const isSucceed = await this.drafts.conflictResolver.resolveConflict(
      well.conflictRigOperation,
      this.drafts.draftVersionId,
      withoutPreloader
    );
    yield;

    if (!isSucceed) {
      throw new ConflictResolvingError();
    }
  }

  @flow.bound
  private async *createWell(): Promise<TCreateUpdateWellReturnType> {
    if (!this.currentFormStore) return;
    assert(this.drafts.draftVersionId, 'draftVersionId is invalid');
    const well = await createWell(this.currentFormStore.tabs, this.drafts.draftVersionId);
    yield;

    this.tripleIds = well.idList;
    this.tripleIdsList[this.currentFormStore.id] = well.idList;
    this.currentFormStore.setRigOperationsIds(well.idList);
    await this.onCreateWell?.(well);

    const withoutPreloader = true;
    const isSucceed = await this.drafts.conflictResolver.resolveConflict(
      well.conflictRigOperation,
      this.drafts.draftVersionId,
      withoutPreloader
    );
    yield;

    if (!isSucceed) {
      throw new ConflictResolvingError();
    }
  }

  @flow.bound
  async *createOrUpdateWell() {
    try {
      this.currentFormStore?.validationManager.validateForm();

      if (!this.currentFormStore?.validationManager.isAllNecessaryControlsAreFilled) {
        return;
      }

      assert(hasValue(this.drafts.draftVersionId));
      this.isSaveUpdateLoading = true;
      this.isFormRequestError = false;

      if (!this.currentSaveUpdateRequest) {
        if (this.tripleIds) {
          this.currentSaveUpdateRequest = this.updateWell();
        } else {
          this.currentSaveUpdateRequest = this.createWell();
        }
      }

      await this.currentSaveUpdateRequest;
      yield;
    } catch (e) {
      yield;
      console.error(e);

      if (!this.isFormRequestError) {
        this.isFormRequestError = true;
      }

      if ((e instanceof Validation400ApiError || e instanceof ValidationError) && e.message) {
        this.notifications.showErrorMessage(e.message);
      } else if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      } else if (e instanceof ConflictResolvingError) {
        this.notifications.showErrorMessageT('errors:failedToResolveConflict');
      } else if (this.tripleIds) {
        this.notifications.showErrorMessageT('newWellForm:Errors.updateWell');
      } else {
        this.notifications.showErrorMessageT('newWellForm:Errors.createWell');
      }
    } finally {
      this.isSaveUpdateLoading = false;
      this.currentSaveUpdateRequest = null;
    }
  }

  @flow.bound
  async *saveForm() {
    if (!this.currentFormStore) {
      return;
    }

    this.isSaveUpdateLoading = true;

    if (this.currentFormStore.validationManager.validationRequest) {
      const isValidationPassed = await this.currentFormStore.validationManager.validationRequest;
      yield;

      // Очищается промис валидации, т.к. если его не очистить, то последующие нажатия неа кнопку "сохранить" не будет рабоать, но в то же время
      // без этого промиса невозможно будет отменить сохранение при ошибке серверной валидации. Если серверная валидация отрабатывает неправильно, то
      // состояние контрола возвращается на изначальное валидное. При первом клике сработает валидация, форма не отправится при ошибке, а ошибочное значение будет отменено
      this.currentFormStore.validationManager.validationRequest = null;

      if (!isValidationPassed) {
        this.isSaveUpdateLoading = false;
        return;
      }
    }

    if (hasValue(this.drafts.draftVersionId)) {
      try {
        //TODO: нужно будет разобраться со всеми видами валидации, собрать их в кучу и перепроектировать
        this.isFormRequestError = false;

        if (!this.currentSaveUpdateRequest) {
          this.currentFormStore?.validationManager.validateForm();
          if (this.tripleIds) {
            this.currentSaveUpdateRequest = this.updateWell();
          } else {
            this.currentSaveUpdateRequest = this.createWell();
          }
        }

        await this.currentSaveUpdateRequest;
        yield;

        if (!this.isSaveUpdateLoading) {
          this.isSaveUpdateLoading = true;
        }

        await this.onFormSaveSuccess?.();
        yield;

        await this.onCloseForm?.();
        yield;
      } catch (e) {
        yield;
        console.error(e);

        if (!this.isFormRequestError) {
          this.isFormRequestError = true;
        }

        if ((e instanceof Validation400ApiError || e instanceof ValidationError) && e.message) {
          this.notifications.showErrorMessage(e.message);
        } else if (e instanceof ConflictResolvingError) {
          this.notifications.showErrorMessageT('errors:failedToResolveConflict');
        } else if (e instanceof BaseApiError && e.responseMessage) {
          this.notifications.showErrorMessage(e.responseMessage);
          return;
        } else if (this.tripleIds) {
          this.notifications.showErrorMessageT('newWellForm:Errors.updateWell');
        } else {
          this.notifications.showErrorMessageT('newWellForm:Errors.createWell');
        }
      } finally {
        this.isSaveUpdateLoading = false;
        this.currentSaveUpdateRequest = null;
      }
    }
  }

  @action.bound
  resetFormManager() {
    this.tripleIds = null;
    this.tripleIdsList = {};
    this.formList = [];
    this.currentFormStore = null;
    this.isFormRequestError = false;
  }

  @action.bound
  cancelForm() {
    this.isFormOpen = false;
    this.resetFormManager();
    this.onFormCancel?.();
    this.onCloseForm?.();
  }
}
