import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx';

import { AddRigSidebar } from 'src/api/chart/add-rig-sidebar-api';
import { debounce } from 'src/shared/utils/debounce';

import { Range } from '../../../layers/model';
import {
  ChartRig,
  LoadingRigOperations,
  PadRigOperation,
  RigsGroup,
  TemporaryChartRig,
  TemporaryRigsGroup,
} from '../../../presets/drilling-rigs-chart/entities';
import { DataView } from '../../../shared/data-view/data-view';
import { RigsDataPositionsCalculator } from '../../../shared/rigs-data-positions-calculator';
import { StorageKeyManagerWithParser } from '../../../shared/storage-key-manager';
import { TimeRangeHelper } from '../../../shared/time-range-helper';
import { TimeUnit } from '../../../shared/time-unit';
import { ViewFrameController } from '../../../shared/view-frame-controller';
import { ViewHelper } from '../../../shared/view-helper';

import { RigsChartDataModel } from './rigs-chart-data-model';
import { IRigsChartDataStorage } from './rigs-chart-data.types';
import { TemporaryRigsStorage } from './temporary-rigs-storage';

export class RigsChartDataStorage implements IRigsChartDataStorage {
  private readonly keyManager: StorageKeyManagerWithParser<Range<number>, string>;
  private readonly viewFrameController = new ViewFrameController<RigsChartDataModel.ViewItem>(
    RigsChartDataStorage.FRAME_HEIGHT
  );
  /*
   * Format:
   *
   * {
   *   rigsGroupId: {
   *     rigId: {
   *       range: Pad[]
   *     }
   *   }
   * }
   *
   * Range - the date range. Format: '${startDate}-${endDate}'.
   * */
  private readonly pads: Map<number, Map<number, Map<string, PadRigOperation[]>>>;
  /*
   * Format:
   *
   * {
   *   rigsGroupId: {
   *     rigId: {
   *       range: EmptyPad
   *     }
   *   }
   * }
   *
   * Range - the date range. Format: '${startDate}-${endDate}'.
   * */
  private readonly emptyPads: Map<number, Map<number, Map<string, LoadingRigOperations>>>;

  @observable private dataView: DataView;
  @observable private horizontalDataRange: Range<number> | null = null;
  // Can be replaced with full chart view object if you need more chart view data here.
  @observable private shownWellAttributesNumber: number = 0;
  @observable private positionsCalculator: RigsDataPositionsCalculator;
  @observable private temporaryRigsStorage = new TemporaryRigsStorage();

  @observable rigsGroups?: RigsGroup[];
  @observable verticalDataRange: Range<number> | null = null;

  constructor(
    keyManager: StorageKeyManagerWithParser<Range<number>, string>,
    dataView: DataView,
    positionsCalculator: RigsDataPositionsCalculator
  ) {
    this.positionsCalculator = positionsCalculator;
    this.keyManager = keyManager;
    this.dataView = dataView;
    this.pads = observable.map();
    this.emptyPads = observable.map();

    makeObservable(this);
  }

  @action.bound
  private setPads(groupId: number, rigId: number, horizontalViewRange: Range<number>, pads: PadRigOperation[]): void {
    const rigsInGroup = this.pads.get(groupId);
    const padsRangeKey = this.keyManager.getKey(horizontalViewRange);

    if (rigsInGroup) {
      const padsInRig = rigsInGroup.get(rigId);

      if (padsInRig) {
        const padsInView = padsInRig.get(padsRangeKey);

        if (padsInView) {
          const existingPadIds = padsInView.map(({ id }) => id);
          const newPads = pads.filter(({ id }) =>
            existingPadIds.find((existingRigOperationId) => existingRigOperationId !== id)
          );

          // Set to Ranges Map: Map<'${startDate}-${endDate}', pad[]>
          padsInRig.set(padsRangeKey, [...padsInView, ...newPads]);
        } else {
          // Set to Ranges Map: Map<'${startDate}-${endDate}', pad[]>
          padsInRig.set(padsRangeKey, pads);
        }
      } else {
        // Set to Rigs Map: Map<rigId, Ranges Map>
        rigsInGroup.set(rigId, observable.map(new Map([[padsRangeKey, pads]])));
      }
    } else {
      // Ranges Map: Map<'${startDate}-${endDate}', pad[]>
      const rangesMap: Map<string, PadRigOperation[]> = observable.map(new Map([[padsRangeKey, pads]]));
      // Rigs Map: Map<rigId, Ranges Map>
      const rigsMap: Map<number, Map<string, PadRigOperation[]>> = observable.map(new Map([[rigId, rangesMap]]));

      // Set to Groups Map: Map<rigsGroupId, Rigs Map>
      this.pads.set(groupId, rigsMap);
    }
  }

  @action.bound
  private setEmptyPads(
    groupId: number,
    rigId: number,
    horizontalViewRange: Range<number>,
    pad: LoadingRigOperations
  ): void {
    const emptyPadRangeKey = this.keyManager.getKey(horizontalViewRange);
    const rigsInGroup = this.emptyPads.get(groupId);

    if (rigsInGroup) {
      const emptyPadsInRig = rigsInGroup.get(rigId);

      if (emptyPadsInRig) {
        const emptyPadsInView = emptyPadsInRig.get(emptyPadRangeKey);

        if (!emptyPadsInView) {
          // Set to Ranges Map: Map<'${startDate}-${endDate}', EmptyPad>
          emptyPadsInRig.set(emptyPadRangeKey, pad);
        }
      } else {
        // Set to Rigs Map: Map<rigId, Ranges Map>
        rigsInGroup.set(rigId, observable.map(new Map([[emptyPadRangeKey, pad]])));
      }
    } else {
      // Ranges Map: Map<'${startDate}-${endDate}', EmptyPad>
      const rangesMap = observable.map(new Map([[emptyPadRangeKey, pad]]));
      // Rigs Map: Map<rigId, Ranges Map>
      const rigsMap = observable.map(new Map([[rigId, rangesMap]]));

      // Set to Groups Map: Map<rigsGroupId, Rigs Map>
      this.emptyPads.set(groupId, rigsMap);
    }
  }

  /** Add empty pads objects to empty data ranges.
   * This allows to determine missing data to download pads and display loading data in view. */
  @action.bound
  private fillInMissingPads(
    verticalViewFrame: Range<number>,
    horizontalViewFrame: Range<number>,
    dataViewType: DataView.DataViewType
  ): void {
    const rigsGroups = this.rigsGroups;

    if (!rigsGroups) {
      return;
    }

    for (let rigsGroupIndex = 0; rigsGroupIndex < rigsGroups.length; rigsGroupIndex++) {
      const rigsGroup = rigsGroups[rigsGroupIndex];

      const isRigsInView =
        rigsGroup.rowsStart &&
        rigsGroup.rowsEnd &&
        ViewHelper.isInView({ start: rigsGroup.rowsStart, end: rigsGroup.rowsEnd }, verticalViewFrame);

      if (rigsGroup && !rigsGroup.isCollapsed && isRigsInView) {
        for (let rigIndex = 0; rigIndex < rigsGroup.items.length; rigIndex++) {
          const rig = rigsGroup.items[rigIndex];

          if (rig && rig.y && ViewHelper.isInView(rig.y, verticalViewFrame)) {
            const padsInRig = this.pads.get(rigsGroup.id)?.get(rig.id);
            const emptyPadsInRig = this.emptyPads.get(rigsGroup.id)?.get(rig.id);
            const rangesOfAllPadsInRig = [...(padsInRig?.keys() || []), ...(emptyPadsInRig?.keys() || [])];

            const horizontalViewFrameChunks = RigsChartDataStorage.getChunksOfDateRange(horizontalViewFrame);
            const emptyRanges: Range<number>[] = [];

            // Iterate chunks of horizontal view frame to fill in empty ranges.
            for (let rangeChunkIndex = 0; rangeChunkIndex < horizontalViewFrameChunks.length; rangeChunkIndex++) {
              const viewFrameChunk = horizontalViewFrameChunks[rangeChunkIndex];

              // Add empty range if there is no chunk of horizontal view frame that contains data or empty range object.
              if (
                !rangesOfAllPadsInRig.find((range) => {
                  const { start, end } = this.keyManager.parseKey(range);
                  return viewFrameChunk.start >= start && viewFrameChunk.end <= end;
                })
              ) {
                emptyRanges.push(viewFrameChunk);
              }
            }

            // Reduce found empty ranges to create empty pads objects and put into the ranges.
            const emptyPads = emptyRanges.reduce((pads: LoadingRigOperations[], currentRange: Range<number>) => {
              const id = Number(`${rigsGroup.id.toString()}${rig.id.toString()}${currentRange.start.toString()}`);

              if (rig.y) {
                const emptyPad = new LoadingRigOperations(id, currentRange);
                emptyPad.setY(
                  this.positionsCalculator.calculatePadPosition(rig.y, dataViewType, rigsGroup.isCollapsed)
                );
                pads.push(emptyPad);
              }

              return pads;
            }, []);

            emptyPads.forEach((pad) => {
              this.setEmptyPads(rigsGroup.id, rig.id, pad.x, pad);
            });
          }
        }
      }
    }
  }

  private fillInMissingPadsDebounced = debounce(
    (verticalViewFrame: Range<number>, horizontalViewFrame: Range<number>, dataViewType: DataView.DataViewType) =>
      this.fillInMissingPads(verticalViewFrame, horizontalViewFrame, dataViewType),
    300
  );

  @computed
  private get rigIds(): number[] | undefined {
    return this.viewFrameController.elements?.reduce((rigIds: number[], element) => {
      if (element instanceof ChartRig) {
        rigIds.push(element.id);
      }

      return rigIds;
    }, []);
  }

  @computed
  private get dataWithTemporaryData(): (RigsGroup | TemporaryRigsGroup)[] {
    const data = this.rigsGroups;

    return this.temporaryRigsStorage.mergeRigGroups(data || []);
  }

  /** Calculate the boundaries of empty data to load them.
   * Takes computed empty ranges and cluster them to rectangular blocks.
   * Returns list of these bounds.
   *
   * @return Boundaries that include horizontal time ranges and vertical rig IDs. */
  @computed
  get missingDataBounds(): RigsChartDataStorage.BlockBounds[] | null {
    if (!this.rigsGroups) {
      return null;
    }

    const missingDataBounds: Map<string, RigsChartDataStorage.BlockBounds> = new Map();

    const sortedEmptyPads = [...this.emptyPads.entries()].sort(
      ([firstRigId], [secondRigId]) =>
        (this.rigsGroups?.findIndex(({ id }) => id === firstRigId) || 0) -
        (this.rigsGroups?.findIndex(({ id }) => id === secondRigId) || 0)
    );

    for (const [groupId, emptyPadsInGroup] of sortedEmptyPads) {
      for (const [rigId, emptyPadsInRig] of emptyPadsInGroup) {
        let rigMissingDataRanges: Range<number>[] = [];

        for (const [emptyPadRangeKey, emptyPad] of emptyPadsInRig) {
          // Continue if empty pad has already been put into missing data.
          if (emptyPad.isInLoading) {
            continue;
          }

          const { start, end } = this.keyManager.parseKey(emptyPadRangeKey);

          // Add new current empty range if there are no missing ranges for current rig.
          if (!rigMissingDataRanges.length) {
            rigMissingDataRanges.push({ start, end });
            continue;
          } else {
            // Iterate the previously added missing ranges to expand them if they are nearby.
            for (
              let rigHorizontalRangeIndex = 0;
              rigHorizontalRangeIndex < rigMissingDataRanges.length;
              rigHorizontalRangeIndex++
            ) {
              const rigHorizontalRange = rigMissingDataRanges[rigHorizontalRangeIndex];

              if (start === rigHorizontalRange.end + 1) {
                rigHorizontalRange.end = end;
                break;
              } else if (end === rigHorizontalRange.start - 1) {
                rigHorizontalRange.start = start;
                break;
              } else if (rigHorizontalRangeIndex === rigMissingDataRanges.length - 1) {
                rigMissingDataRanges.push({ start, end });
                break;
              }
            }
          }

          emptyPad.isInLoading = true;
        }

        // Put previously calculated missing ranges for rig to missing data map.
        for (let rigRangeIndex = 0; rigRangeIndex < rigMissingDataRanges.length; rigRangeIndex++) {
          const rigRange = rigMissingDataRanges[rigRangeIndex];
          const currentRangeKey = `${rigRange.start}-${rigRange.end}`;
          const sameRangeInExistingRanges = missingDataBounds.get(currentRangeKey);
          const group = this.rigsGroups.find(({ id, items }) => id === groupId && items.find(({ id }) => id === rigId));

          if (group) {
            if (sameRangeInExistingRanges) {
              sameRangeInExistingRanges.rigsIds.add(rigId);
              sameRangeInExistingRanges.groupIds.add(groupId);
            } else {
              missingDataBounds.set(currentRangeKey, {
                horizontalRange: { ...rigRange },
                rigsIds: new Set<number>([rigId]),
                groupIds: new Set<number>([group.id]),
              });
            }
          }
        }
      }
    }

    if (!missingDataBounds.size) {
      return null;
    } else {
      return [...missingDataBounds.values()];
    }
  }

  @computed
  get data(): RigsChartDataStorage.DataInView | null {
    const { horizontalFrame, verticalFrame, elements } = this.viewFrameController;

    if (!verticalFrame || !horizontalFrame || !elements) {
      return null;
    }

    return { items: elements, rigIds: this.rigIds };
  }

  @computed
  get allDataVerticalRange(): Range<number> | null {
    return this.positionsCalculator.dataVerticalRange;
  }

  init(): VoidFunction {
    const disposeEmptyPadsCalculating = reaction(
      () => ({
        verticalViewFrame: this.viewFrameController.verticalFrame,
        horizontalViewFrame: this.viewFrameController.horizontalFrame,
      }),
      ({ verticalViewFrame, horizontalViewFrame }) => {
        if (verticalViewFrame !== undefined && horizontalViewFrame !== undefined) {
          this.fillInMissingPadsDebounced(verticalViewFrame, horizontalViewFrame, this.dataView.type);
        }
      }
    );

    const disposeWellsSetter = autorun(() => {
      if (this.rigsGroups) {
        for (const rigsGroup of this.rigsGroups) {
          for (const rig of rigsGroup.items) {
            const padsItems: (PadRigOperation | LoadingRigOperations)[] = [];

            for (const [groupId, padsInGroup] of this.pads) {
              if (rigsGroup.id === groupId) {
                for (const [rigId, padsInRig] of padsInGroup) {
                  if (rigId === rig.id) {
                    for (const [, padsChunk] of padsInRig) {
                      padsItems.push(...padsChunk);
                    }
                  }
                }
              }
            }

            for (const [groupId, padsInGroup] of this.emptyPads) {
              if (rigsGroup.id === groupId) {
                for (const [rigId, padsInRig] of padsInGroup) {
                  if (rigId === rig.id) {
                    for (const [, padsChunk] of padsInRig) {
                      padsItems.push(padsChunk);
                    }
                  }
                }
              }
            }

            rig.setPads(padsItems);
          }
        }

        this.normalizeData();
      }
    });

    const disposeDataPositionsCalculator = reaction(
      () => ({ positionsCalculator: this.positionsCalculator }),
      ({ positionsCalculator }) => {
        if (this.dataWithTemporaryData) {
          this.viewFrameController.clearMap();
          positionsCalculator.calculatePositions(
            this.dataWithTemporaryData,
            this.dataView.type,
            this.shownWellAttributesNumber,
            this.viewFrameController.setElementToFrame
          );
        }
      }
    );

    return () => {
      disposeEmptyPadsCalculating();
      disposeDataPositionsCalculator();
      disposeWellsSetter();
    };
  }

  // Need to be used in calculating positions of chart objects.
  @action.bound
  setShownWellAttributesNumber(attributesNumber: number, dataView: DataView.DataViewType): void {
    this.shownWellAttributesNumber = attributesNumber;
    this.normalizeData();
  }

  @action.bound
  setRigs(rigsGroups: RigsGroup[]): void {
    this.pads.clear();
    this.emptyPads.clear();
    this.viewFrameController.clearMap();

    this.rigsGroups = this.positionsCalculator.calculateRigsAndGroupsPositions(
      rigsGroups,
      this.dataView.type,
      this.shownWellAttributesNumber,
      this.viewFrameController.setElementToFrame
    );
  }

  @action.bound
  updateRigs(rigsGroups: RigsGroup[]): void {
    this.viewFrameController.clearMap();

    this.rigsGroups = this.positionsCalculator.calculateRigsAndGroupsPositions(
      rigsGroups,
      this.dataView.type,
      this.shownWellAttributesNumber,
      this.viewFrameController.setElementToFrame
    );
  }

  @action.bound
  setRigOperations(
    rigOperations: Map<number, Map<number, PadRigOperation[]>>,
    horizontalViewRange: Range<number>,
    dataViewType: DataView.DataViewType
  ): void {
    for (const [groupId, padsROInGroup] of rigOperations) {
      for (const [rigId, padsChunk] of padsROInGroup) {
        const currentPadMap = this.pads.get(groupId)?.get(rigId);
        const allPadsInRig = currentPadMap ? [...currentPadMap.values()].flat() : [];

        const newPads = padsChunk.reduce((newPads: PadRigOperation[], currentPad) => {
          const existingPad = allPadsInRig.find(({ id }) => id === currentPad.id);

          if (existingPad) {
            for (let wellIndex = 0; wellIndex < currentPad.wellRigOperations.length; wellIndex++) {
              const well = currentPad.wellRigOperations[wellIndex];

              if (!existingPad.wellRigOperations.find(({ id }) => id === well.id)) {
                existingPad.setItems([...existingPad.wellRigOperations, well]);
              }
            }
          } else {
            newPads.push(currentPad);
          }

          return newPads;
        }, []);

        this.setPads(groupId, rigId, horizontalViewRange, newPads);
        const emptyPadsInRig = this.emptyPads.get(groupId)?.get(rigId);

        if (emptyPadsInRig) {
          const viewRangeChunks = RigsChartDataStorage.getChunksOfDateRange(horizontalViewRange);

          viewRangeChunks.forEach((range) => {
            const rangeKey = this.keyManager.getKey(range);
            emptyPadsInRig.delete(rangeKey);
          });
        }
      }
    }

    // Filter rigs with new data to calculate only added pads (and wells) positions.
    const data = this.rigsGroups?.filter((rigsGroup) => {
      const filteredRigs = rigsGroup.items.filter(({ id }) => [...rigOperations.keys()].includes(id));

      return !!filteredRigs.length;
    });

    if (data?.length) {
      this.positionsCalculator.calculatePadsPositions(data, dataViewType, this.viewFrameController.setElementToFrame);
    }
  }

  @action.bound
  normalizeData(): void {
    if (this.dataWithTemporaryData) {
      this.viewFrameController.clearMap();
      this.positionsCalculator.calculatePositions(
        this.dataWithTemporaryData,
        this.dataView.type,
        this.shownWellAttributesNumber,
        this.viewFrameController.setElementToFrame
      );

      const { horizontalFrame, verticalFrame } = this.viewFrameController;

      if (verticalFrame && horizontalFrame) {
        this.fillInMissingPadsDebounced(verticalFrame, horizontalFrame, this.dataView.type);
      }
    }
  }

  @action.bound
  setVerticalViewRange(start: number, end: number): void {
    this.viewFrameController.setVerticalViewRange({ start, end });
  }

  @action.bound
  setHorizontalViewRange(start: number, end: number): void {
    this.viewFrameController.setHorizontalViewRange({ start, end });
  }

  @action.bound
  setPositionsCalculator(calculator: RigsDataPositionsCalculator): void {
    this.positionsCalculator = calculator;
  }

  @action.bound
  addTemporaryRig(rig: AddRigSidebar.Rig): TemporaryChartRig {
    const rigsGroup = this.rigsGroups?.find(({ id }) => id === rig.modelId);
    const temporaryRig = this.temporaryRigsStorage.setRig(rigsGroup, rig);

    this.normalizeData();

    return temporaryRig;
  }

  @action.bound
  removeTemporaryRig(): void {
    this.temporaryRigsStorage.clear();
    this.normalizeData();
  }

  @action.bound
  clearRigsData(rigIds: number[]): void {
    for (const rigId of rigIds) {
      for (const [, rigsInGroup] of this.pads) {
        rigsInGroup?.get(rigId)?.clear();
      }
    }
  }
}

export namespace RigsChartDataStorage {
  /** In pixels. */
  export const FRAME_HEIGHT = 800;

  export type BlockBounds = { horizontalRange: Range<number>; rigsIds: Set<number>; groupIds: Set<number> };

  export type DataInView = { items: RigsChartDataModel.ViewItem[]; rigIds?: number[] };

  /** Split view range into small chunks. Chunk size is one year. */
  export const getChunksOfDateRange = (horizontalViewRange: Range<number>): Range<number>[] =>
    TimeRangeHelper.getIntermediateDates(horizontalViewRange, TimeUnit.year);
}
