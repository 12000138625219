import { action, flow, makeObservable, observable } from 'mobx';

import { getWellByWellId } from 'src/api/new-well/requests';
import { TCreateUpdateWellReturnType, TIdTriple } from 'src/api/new-well/types';
import { storage } from 'src/api/storage';
import { BaseApiError } from 'src/errors';
import { LOCAL_STORAGE } from 'src/shared/constants/local-storage';
import { assert } from 'src/shared/utils/assert';
import { RootStore } from 'src/store';
import { Directories } from 'src/store/directories/directories.store';
import { DraftsStore } from 'src/store/drafts/drafts-store';
import { DRAFT_ASSIGNMENT } from 'src/store/drafts/types';
import { I18NextStore } from 'src/store/i18next/i18next-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { PlanVersionStore } from 'src/store/plan-version';
import { RouterStore } from 'src/store/router/router-store';
import { WellFormManager } from 'src/store/well-form-manager/well-form-manager';

export class WellFormStore {
  private readonly notifications: NotificationsStore;
  private readonly directories: Directories;
  private readonly i18: I18NextStore;
  private readonly planVersion: PlanVersionStore;
  private readonly router: RouterStore;
  private readonly draftStore: DraftsStore;
  readonly formManager: WellFormManager;
  currentDraftId: number | null = null;

  @observable isFormInitializing = false;

  constructor(store: RootStore) {
    this.router = store.router;
    this.notifications = store.notifications;
    this.planVersion = store.planVersion;
    this.draftStore = store.drafts;
    this.directories = store.directories;
    this.i18 = store.i18;
    this.formManager = new WellFormManager(store);

    makeObservable(this);
  }

  // Функционал временно отключен
  // saveFormInLocalStorage = (): void => {
  //   if (!this.form) return;
  //   storage.SET('NewWell', serializeForm(this.form));
  // };

  @action.bound
  setIsFormInitializing(is: boolean) {
    this.isFormInitializing = is;
  }

  @flow.bound
  async *initializeForm() {
    const planVersion = this.planVersion.version;
    this.setIsFormInitializing(true);

    if (!planVersion) {
      this.setIsFormInitializing(false);
      return;
    }

    try {
      let well = null;

      if (
        !this.draftStore.hasDraft ||
        !this.draftStore.draftVersionId ||
        !this.draftStore.draftName?.includes(DRAFT_ASSIGNMENT.addWell)
      ) {
        await this.draftStore.createDraft(planVersion.id, DRAFT_ASSIGNMENT.addWell);
      } else {
        const currentDraftId = this.draftStore.draftVersionId;

        const storedTriplesIds: TIdTriple[] | null = storage.GET(
          `${LOCAL_STORAGE.unfinishedWellIds}_${currentDraftId}`
        );
        const geologicalTaskId = storedTriplesIds?.find((tripleId) => tripleId.objectType === 'GOplan_GeologicalTask');

        if (storedTriplesIds && geologicalTaskId && this.draftStore.draftVersionId) {
          well = await getWellByWellId(geologicalTaskId.id, this.draftStore.draftVersionId);
          yield;
        }
      }

      this.currentDraftId = this.draftStore.draftVersionId;

      if (well) {
        this.formManager.createForm({ well, setBy: 'attrName' });
      } else {
        this.formManager.createForm();
      }

      this.formManager.setIsFormOpen(true);
    } catch (error) {
      yield;
      console.error(error);
      if (error instanceof BaseApiError && error.responseMessage) {
        this.notifications.showErrorMessage(error.responseMessage);
        return;
      }
      //TODO: улучшить обработку ошибок, когда они начнут возвращаться
      this.notifications.showErrorMessageT('newWellForm:Errors.recieveForm');
    } finally {
      this.setIsFormInitializing(false);
    }
  }

  async onFormSaveSuccess() {
    assert(this.draftStore.draftVersionId, 'draft id is not presented');

    if (!this.draftStore.draftName || !this.draftStore.draftName.includes(DRAFT_ASSIGNMENT.addWell)) {
      return;
    }

    await this.draftStore.conflictResolver.checkConflicts(this.draftStore.draftVersionId);
    await this.draftStore.saveToPublicDraft();
  }

  onCloseForm() {
    this.formManager.resetFormManager();
    this.formManager.setIsFormOpen(false);
    this.router.back(-1);
    if (this.currentDraftId) {
      storage.DELETE(`${LOCAL_STORAGE.unfinishedWellIds}_${this.currentDraftId}`);
    }
  }

  async onFormCancel() {
    await this.draftStore.deleteCurrentDraft();
  }

  onCreateWell(well: TCreateUpdateWellReturnType) {
    if (this.currentDraftId) {
      storage.SET(`${LOCAL_STORAGE.unfinishedWellIds}_${this.currentDraftId}`, well.idList);
    }
  }

  onUpdateWell(well: TCreateUpdateWellReturnType) {
    if (this.currentDraftId) {
      storage.SET(`${LOCAL_STORAGE.unfinishedWellIds}_${this.currentDraftId}`, well.idList);
    }
  }

  private checkAndDeleteDeprecatedSavedWells() {
    const regExp = new RegExp(`${LOCAL_STORAGE.unfinishedWellIds}_([0-9]+)`, 'g');
    const values = JSON.stringify(storage.getKeys()).matchAll(regExp);

    for (const [key, draftIdRaw] of values) {
      const draftId = Number(draftIdRaw);
      if (draftId) {
        const foundDraft = this.draftStore.draftsList.find((draft) => draft.id === Number(draftId));
        if (!foundDraft) {
          storage.DELETE(key);
        }
      } else {
        storage.DELETE(key);
      }
    }
  }

  @action.bound
  effect() {
    this.checkAndDeleteDeprecatedSavedWells();

    this.formManager.onFormInit = this.initializeForm.bind(this);
    this.formManager.onCloseForm = this.onCloseForm.bind(this);
    this.formManager.onFormCancel = this.onFormCancel.bind(this);
    this.formManager.onFormSaveSuccess = this.onFormSaveSuccess.bind(this);
    this.formManager.onCreateWell = this.onCreateWell.bind(this);
    this.formManager.onUpdateWell = this.onUpdateWell.bind(this);

    this.formManager.init();
  }
}
